import data from './data'
import actions from './actions'
import useToase from '@/utils/toast'
import { getRouterIdx } from '@/utils/router'
import useTimelines from './useTimelines'

export default () => {
  const { makeToast } = useToase()
  const routerIdx = getRouterIdx()
  const { fetchTimelines } = useTimelines()
  const updateNotice = () => {
    data.notice.isSaving.value = true
    actions.updateNotice({
      idx: routerIdx,
      title: data.notice.title.value,
      content: data.notice.content.value,
      state: data.notice.state.value,
    }).then(() => {
      fetchTimelines()
      makeToast('primary', '공지사항 수정에 성공했습니다')
    }).catch(() => {
      makeToast('danger', '공지사항 수정에 실패했습니다')
    }).finally(() => {
      data.notice.isSaving.value = false
    })
  }

  return {
    updateNotice,
    isSaving: data.notice.isSaving,
  }
}
