import data from './data'
import actions from './actions'
import { getRouterIdx } from '@/utils/router'
import useToast from '@/utils/toast'

export default () => {
  const routerIdx = getRouterIdx()
  const { makeToast } = useToast()
  const fetchDetail = () => {
    actions.fetchDetail({
      idx: routerIdx,
    }).then(response => {
      const resData = response.data.data
      data.notice.idx.value = resData.idx
      data.notice.title.value = resData.title
      data.notice.content.value = resData.content
      data.notice.state.value = resData.state
      data.notice.created_at.value = resData.created_at
    }).catch(() => {
      makeToast('danger', '공지사항 정보를 불러오는데 실패했습니다')
    })
  }
  return {
    fetchDetail,
  }
}
