<template>
  <TagbyActionCard>
    <template #left>
      <UpdateButton />
    </template>
  </TagbyActionCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionCard from '@/components/TagbyActionCard.vue'
import UpdateButton from './UpdateButton.vue'

export default defineComponent({
  components: {
    TagbyActionCard,
    UpdateButton,
  },
  setup() {

  },
})
</script>
