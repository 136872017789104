<template>
  <TagbyDetailLayout>
    <template #content>
      <NoticeInfo />
    </template>

    <template #side>
      <ActionCard />
      <TagbyTimelinesV2 :timelines="timelines" />
    </template>

    <template #hidden />
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyDetailLayout from '@/components/TagbyDetailLayout.vue'
import NoticeInfo from './blocks/NoticeInfo'
import ActionCard from './blocks/ActionCard'
import useFetchDetail from './useFetchDetail'
import useTimelines from './useTimelines'
import TagbyTimelinesV2 from '@/components/TagbyTimelinesV2.vue'

export default defineComponent({
  components: {
    TagbyDetailLayout,
    NoticeInfo,
    ActionCard,
    TagbyTimelinesV2,
  },
  setup() {
    const {
      fetchDetail,
    } = useFetchDetail()
    fetchDetail()

    const {
      timelines,
      fetchTimelines,
    } = useTimelines()
    fetchTimelines()

    return {
      timelines,
    }
  },
})
</script>
