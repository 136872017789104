<template>
  <TagbyActionButton
    variant="outline-primary"
    text="저장"
    :isSaving="isSaving"
    :isValid="true"
    @click="updateNotice"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButton from '@/components/TagbyActionButton.vue'
import useUpdateNotice from '../../useUpdateNotice'

export default defineComponent({
  components: {
    TagbyActionButton,
  },
  setup() {
    return {
      ...useUpdateNotice(),
    }
  },
})
</script>
