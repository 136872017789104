import {
  ref,
} from '@vue/composition-api'

export default {
  notice: {
    idx: ref(),
    title: ref(),
    content: ref(),
    state: ref(),
    created_at: ref(),
    isSaving: ref(false),
  },
  timelines: ref([]),
}
