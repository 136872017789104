import data from './data'

export default () => {
  const stateOptions = [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'INACTIVE', value: 'INACTIVE' },
  ]
  return {
    notice: data.notice,
    idx: data.notice.idx,
    title: data.notice.title,
    content: data.notice.content,
    state: data.notice.state,
    createdAt: data.notice.created_at,
    stateOptions,
  }
}
