<template>
  <BCard title="정보">
    <BRow>
      <BCol cols="4">
        <BFormGroup label="IDX">
          <BFormInput
            :value="idx"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="CREATED_AT">
          <TagbyDatetimeInputV2
            :value="createdAt"
            disabled
          />
        </BFormGroup>
      </BCol>
      <BCol cols="4">
        <BFormGroup label="STATE">
          <TagbySelect
            v-model="state"
            :options="stateOptions"
            :reduce="option => option.value"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol cols="12">
        <BFormGroup label="TITLE">
          <BFormInput
            v-model="title"
          />
        </BFormGroup>
      </BCol>
    </BRow>
    <BRow>
      <BCol>
        <BFormGroup label="CONTENT">
          <TagbyEditor v-model="content" />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue'
import TagbyEditor from '@/components/TagbyEditor.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import TagbyDatetimeInputV2 from '@/components/TagbyDatetimeInputV2.vue'
import useNoticeInfo from '../../useNoticeInfo'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    TagbyEditor,
    TagbySelect,
    TagbyDatetimeInputV2,
  },
  setup() {
    return {
      ...useNoticeInfo(),
    }
  },
})
</script>

<style>
.ql-container ql-snow * {
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui,
    Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.ql-container a {
  color: #0078f0;
  text-decoration: underline;
}

.ql-container img {
  width: 100%;
}

.ql-container h1 {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 20px;
}

.ql-container p {
  margin-bottom: 16px;
}

.ql-container ul,
.ql-container ol {
  list-style-type: disc;
  padding-left: 24px;
}
</style>
